import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import api from "../../services/api.service";
import { validate } from "./validations";
import { makeMessages, messageActions } from "../../store/message"; 
const BASE_PATH = "/lognex/scriptwriter";
const prefix = "crudScriptwriter";
const crud = build(validate, BASE_PATH);

const newTypes = {
  SET_RECORDS: `${prefix}setRecords`,
  ON_LOAD: `${prefix}onLoad`,
  ON_LOAD_ORDERS: `${prefix}onLoadOrders`,
  SET_ORDERS_RECORDS: `${prefix}setOrdersRecords`,
  FINDALL_BUSINESS_UNITS: `${prefix}findAllBusinessunits`,
  SET_ALL_BUSINESUNITS: `${prefix}setAllBusinessunits`,
  SET_EXPANDED_ROW: `${prefix}setExpandedRows`,
  SET_SELECTED_ROUTES: `${prefix}setSelectedRoutes`,
  SET_INITIAL_DATE_FILTER: `${prefix}setInitialDateFilter`,
  SET_FINAL_DATE_FILTER: `${prefix}setFinalDateFilter`,
  SET_ROWS_ORDERS_SELECTED_NOT_SCRIPTED: `${prefix}setOrdersNotScriptedSeletected`,
  ROTEIRIZAR_ORDENS_SELECTED: `${prefix}onClickRoteirizarOrdens`,
  SET_FILTERS: `${prefix}setFilters`,
  ON_REMOVE_ROUTE: `${prefix}onRemoveRoute`,
  CHANGE_MOSTRAR_TODAS: `${prefix}changeMostrarTodas`,
  SET_USERS_SELECTED: `${prefix}setUsersSelected`,
  SET_CURRENT_USER: `${prefix}setCurrentUser`,
  ON_REMOVE_DELIVERY_FROM_ROUTE: `${prefix}onRemoveDeliveryRoute`,
  SHOW_SELECTE_ROUTE_ADD_ORDER: `${prefix}showRoutesSelectAddOrders`,
  SET_SELECTE_ROUTE_ADD_ORDER: `${prefix}setSelectedRouterIncludDelivery`,
  SET_DELIVERIES_IN_ROUTE: `${prefix}onConfirmIncludeDeliveryRotaSelected`,
  SHOW_BOX_INFO_SELECTED_DELIVERY_ON_MAP: `${prefix}showBoxInfoDeliveryMap`,
  SET_SELECTED_VIRTUAL_CD: `${prefix}setSelectecVirtualCD`,
  SET_DELIVERY_CD_REMOVE: `${prefix}setDeliveryToRemoveChangeCD`,
  ON_LOAD_VEHICLES: `${prefix}onLoadVehicles`,
  SET_VEHICLES_RECORDS: `${prefix}setVehicles`,
  SET_ROUTE_EDIT_VEHICLE: `${prefix}setRouteChangeVehicle`,
  SET_VEHICLE_TO_CHANGE: `${prefix}setVehicleToChange`,
  SET_CHANGE_VEHICLE_ROUTE: `${prefix}setChangeVehicleTypeRoute`,
  SET_SELECTED_ORDER_DEVIDE_ROUTE: `${prefix}setSelectedOrdersDivideRoute`,
  SET_SELECTED_ORDER_EDIT: `${prefix}showEditDelivery`,
  SET_VISIBLE_CREATE_MANUAL_ROUTE: `${prefix}setVisibleCreateManualRoute`,
  SET_SELECTED_CD_FILTER_IN_ROUTE: `${prefix}onSetSelectedCdFilter`,
  ON_REMOVE_ORDER: `${prefix}onRemoveOrder`,
  SET_TYPE_VIEW: `${prefix}setTypeView`,
  SET_CREATE_EMBARQUE_ROUTE: `${prefix}setCreateEmbarqueRoute`,
  SET_OPEN_MODAL_GROUP_BY_CLIENTS: `${prefix}setOpenModalGrupoByClients`,
  SET_SELECTED_VEHICLE_GROUP_BY_CLIENTS: `${prefix}setTypeSelectedVeicleGroupClient`,
  SET_SELECTED_CD_GROUP_BY_CLIENTS: `${prefix}setTypeSelectedCDGroupClient`,
  ON_REMOVE_ALL_ROUTES_BY_IDS: `${prefix}onRemoveAllRoutes`,
  ON_ENABLE_VEHICLE: `${prefix}onEnableVehicle`,
  ON_ADD_PONTO_APOIO: `${prefix}addPontoApoio`,
  UPDATE_POSITION_PONTO_APOIO: `${prefix}updatePostionPontoApoio`,
  SET_TOLLS: `${prefix}setAllTolls`,
  CHANGE_MOSTRAR_CERCA_VIRTUAL: `${prefix}changeMostrarCercaVirtual`,
  SET_VEHICLES_DRIVER_AVAILABLE: `${prefix}setVehiclesDriverAvailable`,
  ON_CHANGE_DRIVE_FROM_ROUTE: `${prefix}onChangeDriveFromRoute`,
  SET_CHANGE_ROUTE_VEHICLE_PLATE: `${prefix}setChangeVehicleAvailableInRoute`, 
};

const actions = new Action(prefix, crud);
const types = Object.assign({}, actions.types, newTypes);

export { types, actions };

actions.setChangeVehicleAvailableInRoute = (vehiclePlate,routeId) => {
  return {
    type: types.SET_CHANGE_ROUTE_VEHICLE_PLATE,
    vehiclePlate,
    routeId
  };
};

actions.onChangeDriveFromRoute = (route) => {
  return {
    type: types.ON_CHANGE_DRIVE_FROM_ROUTE,
    route,
  };
}

actions.changeMostrarCercaVirtual = (showCercaVirtual) => {
  return {
    type: types.CHANGE_MOSTRAR_CERCA_VIRTUAL,
    showCercaVirtual,
  };
};

actions.setAllTolls = (tolls) => {
  return {
    type: types.SET_TOLLS,
    tolls
  };
}

actions.updatePostionPontoApoio = (postionPontApoio) => {
  return {
    type: types.UPDATE_POSITION_PONTO_APOIO,
    postionPontApoio
  };
}
actions.addPontoApoio = () => {
  return {
    type: types.ON_ADD_PONTO_APOIO
  };
}

actions.onEnableVehicle = (params) => {
  return {
    type: types.ON_ENABLE_VEHICLE,
    params,
  };
}
actions.setTypeSelectedCDGroupClient = (params) => {
  return {
    type: types.SET_SELECTED_CD_GROUP_BY_CLIENTS,
    params,
  };
}
actions.setTypeSelectedVeicleGroupClient = (params) => {
  return {
    type: types.SET_SELECTED_VEHICLE_GROUP_BY_CLIENTS,
    params,
  };
}
actions.setOpenModalGrupoByClients = (show) => {
  return {
    type: types.SET_OPEN_MODAL_GROUP_BY_CLIENTS,
    show,
  };
};

actions.setCreateEmbarqueRoute = (oids, enable) => {
  return {
    type: types.SET_CREATE_EMBARQUE_ROUTE,
    oids, enable,
  };
};


actions.setTypeView = (typeView) => {
  return {
    type: types.SET_TYPE_VIEW,
    typeView,
  };
};

actions.onSetSelectedCdFilter = (filterCDSelected) => {
  return {
    type: types.SET_SELECTED_CD_FILTER_IN_ROUTE,
    filterCDSelected,
  };
};


actions.setVisibleCreateManualRoute = (visible) => {
  return {
    type: types.SET_VISIBLE_CREATE_MANUAL_ROUTE,
    visible,
  };
};

actions.showEditDelivery = (orderEdit) => {
  return {
    type: types.SET_SELECTED_ORDER_EDIT,
    orderEdit,
  };
};

actions.setSelectedOrdersDivideRoute = (selectedOrdersDivideRoute) => {
  return {
    type: types.SET_SELECTED_ORDER_DEVIDE_ROUTE,
    selectedOrdersDivideRoute,
  };
};
actions.setVehicleToChange = (vehicleSelected) => {
  return {
    type: types.SET_VEHICLE_TO_CHANGE,
    vehicleSelected,
  };
};

actions.setRouteChangeVehicle = (routeEditVehicle) => {
  return {
    type: types.SET_ROUTE_EDIT_VEHICLE,
    routeEditVehicle,
  };
};

actions.setVehicles = (records) => {
  return {
    type: types.SET_VEHICLES_RECORDS,
    records,
  };
};
actions.setDeliveryToRemoveChangeCD = (deliveryCDremove) => {
  return {
    type: types.SET_DELIVERY_CD_REMOVE,
    deliveryCDremove,
  };
};
actions.showBoxInfoDeliveryMap = (delivery, operationType) => {
  return {
    type: types.SHOW_BOX_INFO_SELECTED_DELIVERY_ON_MAP,
    delivery, operationType
  };
};

actions.setSelectedRouterIncludDelivery = (route) => {
  return {
    type: types.SET_SELECTE_ROUTE_ADD_ORDER,
    route,
  };
};
actions.showRoutesSelectAddOrders = (value) => {
  return {
    type: types.SHOW_SELECTE_ROUTE_ADD_ORDER,
    value,
  };
};

actions.changeMostrarTodas = (mostrarTodas) => {
  return {
    type: types.CHANGE_MOSTRAR_TODAS,
    mostrarTodas,
  };
};

actions.setFilters = (filterParams) => {
  return {
    type: types.SET_FILTERS,
    filterParams,
  };
};
actions.setOrdersNotScriptedSeletected = (selectedOrderns) => {
  return {
    type: types.SET_ROWS_ORDERS_SELECTED_NOT_SCRIPTED,
    selectedOrderns,
  };
};

actions.setAllBusinessunits = (records) => {
  return {
    type: types.SET_ALL_BUSINESUNITS,
    records,
  };
};

actions.deliveryDateChangeHandler = (dateTime) => {
  return {
    type: types.SET_DELIVERY_DATETIME,
    dateTime,
  };
};

actions.findAllBusinessunits = () => {
  return (dispatch, getState) => {
    api
      .post("/api/v1/lognex/businessunits/findAllbusinessunits")
      .then(({ data }) => {
        dispatch(actions.setAllBusinessunits(data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

actions.setRecords = (records) => {
  return {
    type: types.SET_RECORDS,
    records,
  };
};

actions.setOrdersRecords = (orders) => {
  return {
    type: types.SET_ORDERS_RECORDS,
    orders,
  };
};

actions.setVehiclesDriverAvailable = (vehiclesDriverAvailable) => {
  return {
    type: types.SET_VEHICLES_DRIVER_AVAILABLE,
    vehiclesDriverAvailable
  };
};

//buscar todos os veiculos disponiveis
///vehicles/findByLabel/available
actions.findAllVehiclesAvailable = () => {
  return (dispatch, getState) => {
    
    api.post("/api/v1/lognex/vehicles/findByLabel/available", { driverDisponivel: true, label: "" })
      .then(({ data }) => {
        dispatch(actions.setVehiclesDriverAvailable(data));    
      })
      .catch((err) => {
        console.log(err);
        dispatch(actions.setVehiclesDriverAvailable([]));  
      });
  };
};


actions.onLoad = (filters) => {
  return (dispatch, getState) => {
    dispatch(actions.showLoader());
    dispatch(actions.findAllVehiclesAvailable());
    api
      .post("/api/v1/lognex/scriptwriter/all", { filters })
      .then(({ data }) => {        
        dispatch(actions.setRecords(data));
        dispatch(actions.hideLoader());
      })
      .catch((err) => {
        console.log(err);
        dispatch(actions.hideLoader());
      });
    
  };
};

actions.onLoadOrders = (filters) => {
  return (dispatch, getState) => {
    dispatch(actions.showLoader());
    api
      .post("/api/v1/lognex/scriptwriter/orders", { filters })
      .then(({ data }) => {
        dispatch(actions.setOrdersRecords(data));
        dispatch(actions.hideLoader());
      })
      .catch((err) => {
        console.log(err);
        dispatch(actions.hideLoader());
      });
  };
};

actions.onClickRoteirizarOrdens = () => {
  return (dispatch, getState) => {
    const ordens = getState().crudScriptwriterState.ordersNotScriptedSeletected;
    const filterParams = getState().crudScriptwriterState.filterParams;
    dispatch(actions.showLoader());
    api
      .post("/api/v1/lognex/scriptwriter/scriptorders", { ordens ,closedRoute: false})
      .then(({ data }) => {
        dispatch(actions.hideLoader());
        if (data.error) {
          console.log("scriptorders data.error=", data.error)
          let message = "Não foi possível roteirizar as deliveries";
          if (data.error && typeof data.error === 'string')
            message = data.error;
          dispatch(messageActions.messageShowMessages(makeMessages([message], "error")));

        } else {

          dispatch(actions.setOrdersNotScriptedSeletected([]));
          dispatch(messageActions.messageShowMessages(makeMessages(["Deliveries roteirizada com sucesso!"], "success")));
        }
        dispatch(actions.onLoadOrders(filterParams));
        dispatch(actions.onLoad(filterParams));
      })
      .catch((err) => {
        console.log(err);
        dispatch(actions.hideLoader());
        dispatch(messageActions.messageShowMessages(makeMessages(["Não foi possível roteirizar as deliveries"], "error")));
      });
  };
};

actions.onRemoveRoute = (id) => {
  return (dispatch, getState) => {
    const filterParams = getState().crudScriptwriterState.filterParams;
    dispatch(actions.showLoader());
    api
      .remove("/api/v1/lognex/scriptwriter/" + id, {})
      .then(({ data }) => {
        if (data.error) {
          console.log("scriptorders data.error=", data.error)
          let message = "Não foi possível remover a rota";
          if (data.error && typeof data.error === 'string')
            message = data.error;
          dispatch(messageActions.messageShowMessages(makeMessages([message], "error")));
        } else {
          dispatch(actions.findAllVehiclesAvailable());
          dispatch(actions.setExpandedRows([]));
          dispatch(actions.setSelectedRoutes([]));
          dispatch(actions.setRecords([]));
          dispatch(actions.onLoadOrders(filterParams));
          dispatch(actions.onLoad(filterParams));
          dispatch(messageActions.messageShowMessages(makeMessages(["Rota removida com sucesso!"], "success")));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(actions.hideLoader());
        dispatch(messageActions.messageShowMessages(makeMessages(["Não foi possível remover a rota"], "error")));
      });
  };
};

actions.onRemoveDeliveryRoute = (codes, routeId) => {
  return (dispatch, getState) => {
    const { filterParams, startLocation = null } = getState().crudScriptwriterState;

    let startPointLocation;
    if (startLocation && startLocation.location) {
      startPointLocation = startLocation.location;
    }
    const payload = {
      codes,
      startLocation: startPointLocation,
      routeId,
      preservarVeiculo: true, //inclusao e exclusao de orden dentro da rota, deve-se manter o veiculo mesmo que ultrapasse as capacidades do veiculo
      closedRoute: false,
    };

    dispatch(actions.showLoader());
    api
      .post("/api/v1/lognex/scriptwriter/remove/delivery", payload)
      .then(({ data }) => {
        if (data.error) {
          console.log("scriptorders data.error=", data.error)
          let message = "Não foi possível remover a delivery";
          if (data.error && typeof data.error === 'string')
            message = data.error;
          dispatch(messageActions.messageShowMessages(makeMessages([message], "error")));
        } else {
          dispatch(messageActions.messageShowMessages(makeMessages(["Delivery removida com sucesso!"], "success")));
        }
        dispatch(actions.findAllVehiclesAvailable());
        dispatch(actions.onLoadOrders(filterParams));
        dispatch(actions.onLoad(filterParams));
      })
      .catch((err) => {
        console.log(err);
        dispatch(actions.hideLoader());
        dispatch(messageActions.messageShowMessages(makeMessages(["Não foi possível remover a delivery"], "error")));
        dispatch(actions.onLoadOrders(filterParams));
        dispatch(actions.onLoad(filterParams));
      });
    dispatch(actions.setDeliveryToRemoveChangeCD(null));
  };
};

actions.setExpandedRows = (expandedRows) => {
  return {
    type: types.SET_EXPANDED_ROW,
    expandedRows,
  };
};

actions.setSelectedRoutes = (selectedRoutes) => {
  return {
    type: types.SET_SELECTED_ROUTES,
    selectedRoutes,
  };
};

actions.setInitialDateFilter = (initialDate) => {
  return {
    type: types.SET_INITIAL_DATE_FILTER,
    initialDate,
  };
};

actions.setFinalDateFilter = (finalDate) => {
  return {
    type: types.SET_FINAL_DATE_FILTER,
    finalDate,
  };
};

actions.setUsersSelected = (user) => {
  return {
    type: types.SET_USERS_SELECTED,
    user,
  };
};

actions.setCurrentUser = (currentUser) => {
  return {
    type: types.SET_CURRENT_USER,
    currentUser,
  };
};

actions.setSelectecVirtualCD = (startLocation) => {
  return {
    type: types.SET_SELECTED_VIRTUAL_CD,
    startLocation,
  };
};

actions.onConfirmIncludeDeliveryRotaSelected = () => {
  return (dispatch, getState) => {
    const { selectedRouterIncludDelivery, ordersNotScriptedSeletected, filterParams, startLocation = null } = getState().crudScriptwriterState;

    const listCodes = []
    for (const ord of ordersNotScriptedSeletected) {
      if (!(ord.type && ord.type === "pontoApoio")) {
        listCodes.push(ord.oid);
      }
    }
    //type: "pontoApoio"
    const listApoio = [];
    for (const ap of ordersNotScriptedSeletected) {
      if (ap.type && ap.type === "pontoApoio") {
        listApoio.push(ap);
      }
    }

    let startPointLocation;
    if (startLocation && startLocation.location) {
      startPointLocation = startLocation.location;
    }
    const payload = {
      routeId: selectedRouterIncludDelivery.oid,
      deliveries: listCodes,
      listApoio,
      startLocation: startPointLocation,
      preservarVeiculo: true, //inclusao e exclusao de orden dentro da rota, deve-se manter o veiculo mesmo que ultrapasse as capacidades do veiculo
      closedRoute: false,
    };

    dispatch(actions.showLoader());
    api
      .post("/api/v1/lognex/scriptwriter/include/delivery", payload)
      .then(({ data }) => {
        //error: null, result:
        dispatch(actions.showRoutesSelectAddOrders(false));
        if (data.error) {
          let message = "Não foi possível roteirizar as deliveries";
          if (data.error && typeof data.error === 'string')
            message = data.error;
          dispatch(messageActions.messageShowMessages(makeMessages([message], "error")));

        } else {

          dispatch(messageActions.messageShowMessages(makeMessages(["Deliveries roteirizadas com sucesso!"], "success")));
        }
        dispatch(actions.setOrdersNotScriptedSeletected([]));
        dispatch(actions.onLoadOrders(filterParams));
        dispatch(actions.onLoad(filterParams));
      })
      .catch((err) => {
        console.log(err);
        dispatch(actions.hideLoader());
        dispatch(actions.onLoadOrders(filterParams));
        dispatch(actions.onLoad(filterParams));
        dispatch(messageActions.messageShowMessages(makeMessages(["Não foi possível roteirizar as deliveries"], "error")));
      });
  };
};

actions.onLoadVehicles = () => {
  return (dispatch, getState) => {
    dispatch(actions.showLoader());
    api
      .get("/api/v1/lognex/scriptwriter/vehicles")
      .then(({ data }) => {
        dispatch(actions.setVehicles(data));
        dispatch(actions.hideLoader());
      })
      .catch((err) => {
        console.log(err);
        dispatch(actions.hideLoader());
        dispatch(messageActions.messageShowMessages(makeMessages(["Não foi possível carregar os tipos de veículos"], "error")));
      });
  };
};

actions.setChangeVehicleTypeRoute = (id) => {
  return (dispatch, getState) => {
    const vehicle = getState().crudScriptwriterState.vehicleSelected;
    const routeEditVehicle = getState().crudScriptwriterState.routeEditVehicle;
    const filterParams = getState().crudScriptwriterState.filterParams;

    const payload = { routeId: routeEditVehicle.oid, vehicle };
    dispatch(actions.showLoader());
    dispatch(actions.setVehicleToChange(null));
    dispatch(actions.setRouteChangeVehicle(null));

    api
      .post("/api/v1/lognex/scriptwriter/changeVehicleType", payload)
      .then(({ data }) => {
        dispatch(actions.hideLoader());
        if (data.error) {
          let message = "Não foi possível alterar o veículo da rota";
          if (data.error && typeof data.error === 'string')
            message = data.error;
          dispatch(messageActions.messageShowMessages(makeMessages([message], "error")));
        } else {
          dispatch(messageActions.messageShowMessages(makeMessages(["Veículo alterado com sucesso!"], "success")));
        }
        dispatch(actions.onLoadOrders(filterParams));
        dispatch(actions.onLoad(filterParams));
      })
      .catch((err) => {
        console.log(err);
        dispatch(actions.hideLoader());
        dispatch(actions.onLoadOrders(filterParams));
        dispatch(actions.onLoad(filterParams));
        dispatch(messageActions.messageShowMessages(makeMessages(["Não foi possível veículo alterado da rota"], "error")));
      });
  };
};

actions.onClickQuebrarNovaRota = (routeId) => {
  return (dispatch, getState) => {
    const selectedOrdersDivideRoute = getState().crudScriptwriterState.selectedOrdersDivideRoute;
    const filterParams = getState().crudScriptwriterState.filterParams;

    const payload = { routeId, orders: selectedOrdersDivideRoute };
    dispatch(actions.showLoader());

    api
      .post("/api/v1/lognex/scriptwriter/divideRoute", payload)
      .then(({ data }) => {
        dispatch(actions.hideLoader());
        if (data.error) {
          let message = "Não foi possível dividir a rota";
          if (data.error && typeof data.error === 'string')
            message = data.error;
          dispatch(messageActions.messageShowMessages(makeMessages([message], "error")));
        } else {
          dispatch(messageActions.messageShowMessages(makeMessages(["Rota dividida com sucesso!"], "success")));
        }
        dispatch(actions.onLoadOrders(filterParams));
        dispatch(actions.onLoad(filterParams));
      })
      .catch((err) => {
        console.log(err);
        dispatch(actions.hideLoader());
        dispatch(actions.onLoadOrders(filterParams));
        dispatch(actions.onLoad(filterParams));
        dispatch(messageActions.messageShowMessages(makeMessages(["Não foi possível dividir a rota"], "error")));
      });
  };
};

actions.onChangeInfoDelivery = (order) => {
  return (dispatch, getState) => {
    const filterParams = getState().crudScriptwriterState.filterParams;

    dispatch(actions.showLoader());
    const payload = { preservarVeiculo: true, closedRoute: false, order };

    api
      .post("/api/v1/lognex/scriptwriter/editInfoOrder", payload)
      .then(({ data }) => {
        dispatch(actions.hideLoader());
        if (data.error) {
          let message = "Não foi possível salvar as alterações da ordem";
          if (data.error && typeof data.error === 'string')
            message = data.error;
          dispatch(messageActions.messageShowMessages(makeMessages([message], "error")));
        } else {
          dispatch(actions.showEditDelivery(null));

          dispatch(messageActions.messageShowMessages(makeMessages(["Informações alterada com sucesso!"], "success")));
        } dispatch(actions.onLoad(filterParams));
      })
      .catch((err) => {
        console.log(err);
        dispatch(actions.hideLoader());
        dispatch(messageActions.messageShowMessages(makeMessages(["Não foi possível salvar as alterações da ordem"], "error")));
      });
  };
};

actions.onCreateManualRoute = (createManualRouteObj) => {
  return (dispatch, getState) => {
    const filterParams = getState().crudScriptwriterState.filterParams;
    dispatch(actions.showLoader());
    api
      .post("/api/v1/lognex/scriptwriter/createManualRoute", createManualRouteObj)
      .then(({ data }) => {
        dispatch(actions.hideLoader());
        if (data.error) {
          let message = "Não foi possível gerar uma manual!";
          if (data.error && typeof data.error === 'string')
            message = data.error;
          dispatch(messageActions.messageShowMessages(makeMessages([message], "error")));
        } else {
          dispatch(actions.showEditDelivery(null));

          dispatch(actions.setOrdersNotScriptedSeletected([]));

          dispatch(actions.setVisibleCreateManualRoute(false));
          dispatch(messageActions.messageShowMessages(makeMessages(["Rota manual criada com sucesso!"], "success")));
        }
        dispatch(actions.onLoad(filterParams));
        dispatch(actions.onLoadOrders(filterParams));
      })
      .catch((err) => {
        console.log(err);
        dispatch(actions.hideLoader());
        dispatch(actions.onLoad(filterParams));
        dispatch(actions.onLoadOrders(filterParams));
        dispatch(messageActions.messageShowMessages(makeMessages(["Não foi possível gerar uma manual!"], "error")));
      });
  };
};

actions.onRemoveOrder = (oid) => {
  return (dispatch, getState) => {
    const filterParams = getState().crudScriptwriterState.filterParams;

    dispatch(actions.showLoader());
    api
      .post("/api/v1/lognex/scriptwriter/removeOrder", { oid })
      .then(({ data }) => {
        dispatch(actions.hideLoader());
        if (data.error) {
          let message = "Não foi possível remover a Order!";
          if (data.error && typeof data.error === 'string')
            message = data.error;
          dispatch(messageActions.messageShowMessages(makeMessages([message], "error")));
        } else {

          dispatch(actions.setOrdersNotScriptedSeletected([]));
          dispatch(messageActions.messageShowMessages(makeMessages(["A Order foi removida com sucesso!"], "success")));
        }
        dispatch(actions.onLoad(filterParams));
        dispatch(actions.onLoadOrders(filterParams));
      })
      .catch((err) => {
        console.log(err);
        dispatch(actions.hideLoader());
        dispatch(actions.onLoad(filterParams));
        dispatch(actions.onLoadOrders(filterParams));
        dispatch(messageActions.messageShowMessages(makeMessages(["Não foi possível remover a Order!"], "error")));
      });
  };
};


actions.createRomaneio = (oids) => {
  return (dispatch, getState) => {
    const filterParams = getState().crudScriptwriterState.filterParams;
    // rowData.createEmbarque
    dispatch(actions.setCreateEmbarqueRoute(oids, true));

    dispatch(actions.showLoader());
    api
      .post("/api/v1/lognex/scriptwriter/createRomaneio", { oids })
      .then(({ data }) => {
        dispatch(actions.hideLoader());
        if (data.error) {
          let message = "Não foi possível gerar o romaneio!";
          if (data.error && typeof data.error === 'string')
            message = data.error;
          dispatch(messageActions.messageShowMessages(makeMessages([message], "error")));

        } else {
          dispatch(actions.setOrdersNotScriptedSeletected([]));
          dispatch(messageActions.messageShowMessages(makeMessages(["O romaneio foi gerado com sucesso!"], "success")));
        }
        dispatch(actions.onLoad(filterParams));
        dispatch(actions.onLoadOrders(filterParams));
      })
      .catch((err) => {
        console.log(err);
        dispatch(actions.hideLoader());
        dispatch(actions.onLoad(filterParams));
        dispatch(actions.onLoadOrders(filterParams));
        dispatch(messageActions.messageShowMessages(makeMessages(["Não foi possível gerar o romaneio!"], "error")));
      });
  };
};

actions.onConfirmOptmizeRouteByClient = () => {
  return (dispatch, getState) => {
    dispatch(actions.showLoader());
    const filterParams = getState().crudScriptwriterState.filterParams;
    const listClientsDelivery = getState().crudScriptwriterState.listClientsDelivery;

    api.post("/api/v1/lognex/scriptwriter/optmizeRouteByClient", { listData: listClientsDelivery })
      .then(({ data }) => {
        dispatch(actions.hideLoader());
        if (data.error) {
          let message = "Não foi possível aplicar a otimização por cliente!";
          if (data.error && typeof data.error === 'string')
            message = data.error;
          dispatch(messageActions.messageShowMessages(makeMessages([message], "error")));

        } else {
          dispatch(actions.setOrdersNotScriptedSeletected([]));
          dispatch(messageActions.messageShowMessages(makeMessages(["Não foi possível optmizar as rotas por clientes!"], "success")));
        }
        dispatch(actions.setOpenModalGrupoByClients(false));
        dispatch(actions.onLoad(filterParams));
        dispatch(actions.onLoadOrders(filterParams));
      })
      .catch((err) => {
        dispatch(actions.hideLoader());
        dispatch(actions.onLoad(filterParams));
        dispatch(actions.onLoadOrders(filterParams));
        dispatch(messageActions.messageShowMessages(makeMessages(["Não foi possível optmizar as rotas por clientes!"], "error")));
      });

  };
}

actions.onChangeStartPoint = (newPointStart) => {
  return (dispatch, getState) => {
    dispatch(actions.showLoader());
    const filterParams = getState().crudScriptwriterState.filterParams;

    api.post("/api/v1/lognex/scriptwriter/changeStartPoint", newPointStart)
      .then(({ data }) => {
        dispatch(actions.hideLoader());
        if (data.error) {
          let message = "Não foi possível aplicar alterar o ponto de inicio da rota!";
          if (data.error && typeof data.error === 'string')
            message = data.error;
          dispatch(messageActions.messageShowMessages(makeMessages([message], "error")));

        } else {
          dispatch(messageActions.messageShowMessages(makeMessages(["Ponto de inicio alterado!"], "success")));
        }
        dispatch(actions.onLoad(filterParams));
        dispatch(actions.onLoadOrders(filterParams));
      })
      .catch((err) => {
        dispatch(actions.hideLoader());
        dispatch(actions.onLoad(filterParams));
        dispatch(actions.onLoadOrders(filterParams));
        dispatch(messageActions.messageShowMessages(makeMessages(["Não foi possível aplicar alterar o ponto de inicio da rota!!"], "error")));
      });

  };
}

actions.onRemoveAllRoutes = (routeIds) => {
  return (dispatch, getState) => {
    const filterParams = getState().crudScriptwriterState.filterParams;

    dispatch(actions.showLoader());

    const removeAllRoutes = async () => {
      try {
        const list = routeIds.map(id => {
          return api.remove("/api/v1/lognex/scriptwriter/" + id, {})
        })
        await Promise.all(list)
        dispatch(actions.hideLoader());
        dispatch(actions.onLoad(filterParams));
        dispatch(actions.onLoadOrders(filterParams));
      } catch (error) {
        dispatch(actions.hideLoader());
        dispatch(actions.onLoad(filterParams));
        dispatch(actions.onLoadOrders(filterParams));
        dispatch(messageActions.messageShowMessages(makeMessages(["Não foi possível gerar o romaneio!"], "error")));
      }

    }
    removeAllRoutes();
  }
};

actions.getAllTolls = () => {
  return (dispatch, getState) => {
    api
      .post("/api/v1/lognex/pracas-pedagios/find-all-pracas-pedagios")
      .then(({ data }) => {
        dispatch(actions.setAllTolls(data));
      })
      .catch((err) => {
        console.log("Erro getAllTolls:", err);
        dispatch(actions.setAllTolls([]));
      }); 
  };
}

actions.onConfirmChangeVehiclePlate = ({vehiclePlate, routeId}) => {
  return (dispatch, getState) => {
    const filterParams = getState().crudScriptwriterState.filterParams;
    dispatch(actions.showLoader());
    api
      .post("/api/v1/lognex/scriptwriter/changeVehiclePlateRoute", {vehiclePlate, routeId})
      .then(({ data }) => { 
        dispatch(actions.hideLoader());
        if (data.error) {
          let message = "Não foi possível alterar a placa do veículo!";
          if (data.error && typeof data.error === 'string')
            message = data.error;
          dispatch(messageActions.messageShowMessages(makeMessages([message], "error")));
          
        } else {
           const available = data.result;
           console.log("available:", available);
          //close modal
           dispatch(actions.setChangeVehicleAvailableInRoute(available, routeId)); 
          // dispatch(actions.setVehiclesDriverAvailable([]));
          // dispatch(actions.onChangeDriveFromRoute(null));
          // dispatch(actions.onLoad(filterParams));
          // dispatch(actions.onLoadOrders(filterParams));
    //        this.props.onLoadOrders(filters);
    // this.props.setFilters(filters);
    // this.props.loadCercaVirtual();
          dispatch(messageActions.messageShowMessages(makeMessages(["Placa do veículo alterada com sucesso!"], "success")));
        } 
      })
      .catch((err) => { 
        dispatch(actions.hideLoader());
        dispatch(messageActions.messageShowMessages(makeMessages(["Não foi possível alterar a placa do veículo!"], "error")));
      }); 
  }
}