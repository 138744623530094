
import React from 'react';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import Toolbar from "../../../components/toolbar/Toolbar";
import { actions } from '../vehicle.actions';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { VehicleForm } from './VehicleForm';
import { actions as driverActions } from '../../driver/driver.actions';
import { actions as vehicleTypeActions } from '../../vehicleType/vehicleType.actions';
import { clearSpecialCharacteres, clearCharacteres } from '../../../utils'


const mapStateToProps = state => {
  const record = state.crudVehicleState.currentRecord;

  const lookupDriverBase = {
    records: state.crudDriverState.records,
    columns: state.crudDriverState.columns
      .filter(col => col.field === 'oid' || col.field === 'name')
      .map(col => <Column key={col.field} {...col} />),
    first: state.crudDriverState.index,
    rows: state.crudDriverState.max,
    totalRecords: state.crudDriverState.count,
    sortField: state.crudDriverState.sortField,
    order: state.crudDriverState.sortOrder,
    editDisabled: !record.oidDriver
  }
  let lookupDriver = { ...state.crudVehicleState.lookupDriver, ...lookupDriverBase };

  const lookupVehicleTypeBase = {
    records: state.crudVehicleTypeState.records,
    columns: state.crudVehicleTypeState.columns
      .filter(col => col.field === 'oid' || col.field === 'name')
      .map(col => <Column key={col.field} {...col} />),
    first: state.crudVehicleTypeState.index,
    rows: state.crudVehicleTypeState.max,
    totalRecords: state.crudVehicleTypeState.count,
    sortField: state.crudVehicleTypeState.sortField,
    order: state.crudVehicleTypeState.sortOrder,
    editDisabled: !record.oidVehicleType
  }

  let lookupVehicleType = { ...state.crudVehicleState.lookupVehicleType, ...lookupVehicleTypeBase }

  return {
    record,
    lookupDriver,
    lookupVehicleType,
  }
}

const mapDispatchToProps = dispatch => {
  return {

    lookupDriverSetVisible: visible => dispatch(actions.setLookupDriverVisible(visible)),
    lookupDriverPaginate: paginationParams => dispatch(driverActions.paginate(paginationParams)),
    lookupDriverSortOrder: sortParams => dispatch(driverActions.sortOrder(sortParams)),
    lookupDriverApplyFilter: filterParams => {
      dispatch(driverActions.setType(null));
      dispatch(driverActions.applyFilter(filterParams))
    },
    lookupDriverLoad: () => dispatch(driverActions.load()),
    setDriver: (driver) => {
      dispatch(actions.setField('driver.oid', driver != null ? driver.oid : null));
      dispatch(actions.setField('driver.name', driver != null ? driver.name : ''));
      dispatch(actions.setField('driver.documentNumber', driver != null ? driver.documentNumber : ''));
    },


    lookupVehicleTypeSetVisible: visible => dispatch(actions.setLookupVehicleTypeVisible(visible)),
    lookupVehicleTypePaginate: paginationParams => dispatch(vehicleTypeActions.paginate(paginationParams)),
    lookupVehicleTypeSortOrder: sortParams => dispatch(vehicleTypeActions.sortOrder(sortParams)),
    lookupVehicleTypeApplyFilter: filterParams => {
      dispatch(vehicleTypeActions.setType(null));
      dispatch(vehicleTypeActions.applyFilter(filterParams))
    },
    lookupVehicleTypeLoad: () => dispatch(vehicleTypeActions.load()),
    setVehicleType: (vehicleType) => {
      dispatch(actions.setField('vehicleType.oid', vehicleType != null ? vehicleType.oid : null));
      dispatch(actions.setField('vehicleType.name', vehicleType != null ? vehicleType.name : ''));
      dispatch(actions.setField('vehicleType.label', vehicleType != null ? vehicleType.code : ''));
      dispatch(actions.setField('vehicleType.pallets', vehicleType != null ? vehicleType.pallets : null));
      dispatch(actions.setField('vehicleType.eixos', vehicleType != null ? vehicleType.eixos : 1));
      dispatch(actions.setField('vehicleType.weight', vehicleType != null ? vehicleType.weight : 0));
      dispatch(actions.setField('vehicleType.averageSpeed', vehicleType != null ? vehicleType.averageSpeed : 60));
    },

    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      if (field === "plate") {
        value = clearSpecialCharacteres(value).toUpperCase();
      } else if (field === "numberRenavam") {
        value = clearCharacteres(value);
      }
      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },
    saveHandler: event => {
      dispatch(actions.save());
    },
    removeHandler: event => {
      dispatch(actions.remove());
    },
    newHandler: event => {
      dispatch(actions.makeNew());
    },
    loadById: oid => dispatch(actions.loadById(oid))
  }
}

class VehicleFormPageContainer extends React.Component {

  constructor(props) {
    super(props);
    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_VEHICLE}/${oid}`);
    };
    actions.afterNew = () => {
      this.props.history.replace(`${routes.PATH_VEHICLE}/novo`);
    };
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_VEHICLE}`);
    };
  }

  componentDidMount() {
    const oid = this.props.match.params.id;

    if (oid && parseInt(oid)) {
      this.props.loadById(oid);
    } else {
      this.props.newHandler();
    }
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_VEHICLE}`)
  }

  lookupDriverOnPage = (e) => {
    this.props.lookupDriverPaginate({
      max: e.rows,
      index: e.first
    })
  }
  lookupDriverOnSort = (e) => {
    this.props.lookupDriverSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    })


  }
  lookupDriverOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM'

      })
    })
    this.props.lookupDriverApplyFilter(filters)

  }
  lookupDriverOnClick = (e) => {
    this.props.lookupDriverLoad();
    this.props.lookupDriverSetVisible(true);
  }
  lookupDriverOnHide = (e) => {
    this.props.lookupDriverSetVisible(false);
  }
  lookupDriverOnConfirm = (e) => {
    this.props.setDriver(e.selection);
    this.props.lookupDriverSetVisible(false);

  }
  lookupDriverOnCancel = (e) => {
    this.props.setDriver(null);
    this.props.lookupDriverSetVisible(false);
  }
  lookupDriverOnEdit = () => {
    if (this.props.record.oidPessoa) {
      this.props.history.push(`${routes.PATH_CARRIER}/${this.props.record.oidDriver}`);
    }
  }

  lookupDriverOnComplete = e => {
    console.log(e);
  };

  lookupDriverOnInputFilter = (e) => {
    const value = e.value;
    if (typeof value === 'string') {
      const filters = [{
        campo: "name",
        valor: e.value,
        tipoFiltro: 'CONTEM'

      }];
      this.props.setField("driver.name", e.value);
      this.props.setField("oidDriver", null);
      this.props.lookupDriverApplyFilter(filters)
    } else {
      this.props.setDriver(value);
    }
  }


  // LOOKUP VEHICLETYPE
  lookupVehicleTypeOnPage = (e) => {
    this.props.lookupVehicleTypePaginate({
      max: e.rows,
      index: e.first
    })
  }
  lookupVehicleTypeOnSort = (e) => {
    this.props.lookupVehicleTypeSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    })
  }
  lookupVehicleTypeOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM'

      })
    })
    this.props.lookupVehicleTypeApplyFilter(filters)

  }
  lookupVehicleTypeOnClick = (e) => {
    this.props.lookupVehicleTypeLoad();
    this.props.lookupVehicleTypeSetVisible(true);
  }
  lookupVehicleTypeOnHide = (e) => {
    this.props.lookupVehicleTypeSetVisible(false);
  }
  lookupVehicleTypeOnConfirm = (e) => {
    this.props.setVehicleType(e.selection);
    this.props.lookupVehicleTypeSetVisible(false);

  }
  lookupVehicleTypeOnCancel = (e) => {
    this.props.setVehicleType(null);
    this.props.lookupVehicleTypeSetVisible(false);
  }
  lookupVehicleTypeOnEdit = () => {
    if (this.props.record.oidPessoa) {
      this.props.history.push(`${routes.PATH_CARRIER}/${this.props.record.oidVehicleType}`);
    }
  }

  lookupVehicleTypeOnComplete = e => {
    console.log(e);
  };

  lookupVehicleTypeOnInputFilter = (e) => {
    const value = e.value;
    if (typeof value === 'string') {
      const filters = [{
        campo: "name",
        valor: e.value,
        tipoFiltro: 'CONTEM'

      }];
      this.props.setField("vehicleType.name", e.value);
      this.props.setField("oidVehicleType", null);
      this.props.lookupVehicleTypeApplyFilter(filters)
    } else {
      this.props.setVehicleType(value);
    }
  }


  render() {
    const content = (
      <VehicleForm
        changeHandler={this.props.changeHandler}
        record={this.props.record}

        lookupDriver={this.props.lookupDriver}
        lookupDriverOnHide={this.lookupDriverOnHide}
        lookupDriverOnConfirm={this.lookupDriverOnConfirm}
        lookupDriverOnCancel={this.lookupDriverOnCancel}
        lookupDriverOnPage={this.lookupDriverOnPage}
        lookupDriverOnSort={this.lookupDriverOnSort}
        lookupDriverOnFilter={this.lookupDriverOnFilter}
        lookupDriverOnClick={this.lookupDriverOnClick}
        lookupDriverOnEdit={this.lookupDriverOnEdit}
        lookupDriverOnComplete={this.lookupDriverOnComplete}
        lookupDriverOnInputFilter={this.lookupDriverOnInputFilter}

        lookupVehicleType={this.props.lookupVehicleType}
        lookupVehicleTypeOnHide={this.lookupVehicleTypeOnHide}
        lookupVehicleTypeOnConfirm={this.lookupVehicleTypeOnConfirm}
        lookupVehicleTypeOnCancel={this.lookupVehicleTypeOnCancel}
        lookupVehicleTypeOnPage={this.lookupVehicleTypeOnPage}
        lookupVehicleTypeOnSort={this.lookupVehicleTypeOnSort}
        lookupVehicleTypeOnFilter={this.lookupVehicleTypeOnFilter}
        lookupVehicleTypeOnClick={this.lookupVehicleTypeOnClick}
        lookupVehicleTypeOnEdit={this.lookupVehicleTypeOnEdit}
        lookupVehicleTypeOnComplete={this.lookupVehicleTypeOnComplete}
        lookupVehicleTypeOnInputFilter={this.lookupVehicleTypeOnInputFilter}

      />
    );
    const title = `Veículo ${this.props.record.oid || 'novo'}`
    const header = (
      <div>
        <h1>{title}</h1>
        <div>
          <Toolbar
            onSave={this.props.saveHandler} saveButtonStyle='highlighted'
            onRemove={this.props.removeHandler} removeButtonStyle='danger'
            onNew={this.props.newHandler}
            onSearch={this.searchHandler}>
          </Toolbar>
        </div>
      </div>
    );
    return (<Page header={header} content={content} />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleFormPageContainer);